<template>
  <div>
    <v-row v-if="ano || numero || processoAntigo">
      <v-col cols="1" v-if="ano">
        <v-text-field
          v-model="inputAno"
          @keyup.enter="filterInputs"
          label="Ano"
        ></v-text-field>
      </v-col>
      <v-col cols="2" v-if="numero">
        <v-text-field
          v-model="inputNumero"
          @keyup.enter="filterInputs"
          label="Número"
        ></v-text-field>
      </v-col>
      <v-col cols="3" v-if="processoAntigo">
        <v-text-field
          v-model="inputProcessoAntigo"
          @keyup.enter="filterInputs"
          label="Processo antigo"
        ></v-text-field>
      </v-col>
      <v-col cols="3" v-if="data">
        <v-text-field
          v-model="inputData"
          @keyup.enter="filterInputs"
          label="Data do processo"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-switch
          v-model="inputFavorito"
          label="Somente os favoritos"
          @change="filterInputs"
          color="primary"
          flat
        >
        </v-switch>
      </v-col>
    </v-row>

    <v-row v-if="numero_doc_upload || origem_doc_upload || body">
      <v-col cols="3" v-if="body">
        <v-text-field
          v-model="inputBody"
          @keyup.enter="filterInputs"
          label="Conteúdo do documento"
        ></v-text-field>
      </v-col>
      <v-col cols="3" v-if="numero_doc_upload">
        <v-text-field
          v-model="inputNumeroUpload"
          @keyup.enter="filterInputs"
          label="Número do documento externo"
        ></v-text-field>
      </v-col>
      <v-col cols="3" v-if="origem_doc_upload">
        <v-text-field
          v-model="inputOrigemUpload"
          @keyup.enter="filterInputs"
          label="Origem do documento externo"
        ></v-text-field>
      </v-col>
      <v-col cols="3" v-if="data_doc_upload">
        <v-text-field
          v-model="inputData"
          @keyup.enter="filterInputs"
          label="Data do documento"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <template v-for="item in arrayFilters">
        <v-col :cols="dynamicCol" :key="item.identifier">
          <v-autocomplete
            :items="item.arr"
            item-text="nome"
            item-value="id"
            :label="item.label"
            return-object
            clearable
            @click:clear="clear(item)"
            @change="[chooseFilter(item, $event)]"
          />
        </v-col>
      </template>
    </v-row>

    <v-row>
      <v-col cols="6"></v-col>

      <v-col cols="2">
        <v-btn block color="accent" dark @click="filterInputs">
          <v-icon left>search</v-icon>
          Pesquisar
        </v-btn>
      </v-col>
      <v-col cols="2">
        <v-btn block color="accent" dark @click="filterExport">
          <v-icon left>mdi-database-export</v-icon>
          Exportar
        </v-btn>
      </v-col>
      <v-col cols="2">
        <v-btn block color="accent" dark @click="filterPdf">
          <v-icon left>mdi-printer</v-icon>
          PDF
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    organizacao: Boolean,
    ano: Boolean,
    numero: Boolean,
    processoAntigo: Boolean,
    data: Boolean,
    tipodoc: Boolean,
    tipoprocesso: Boolean,
    assunto: Boolean,
    nivel: Boolean,
    situacao: Boolean,
    interessado: Boolean,
    numero_doc_upload: Boolean,
    origem_doc_upload: Boolean,
    data_doc_upload: Boolean,
    body: Boolean,
    filterusuario: Boolean,
    itemsArr: Array,
    qtdFilters: Number,
  },
  data() {
    return {
      dynamicCol: null,
      count: 0,
      inputFavorito: null,
      inputAno: null,
      inputNumero: null,
      inputData: null,
      inputProcessoAntigo: null,
      inputBody: null,
      inputNumeroUpload: null,
      inputOrigemUpload: null,
      arrayInputs: [],
      arrayFilters: [],
    };
  },
  watch: {
    count() {
      if (this.count == this.qtdFilters)
        this.dynamicCol = Math.trunc(12 / this.arrayFilters.length);
    },
  },
  methods: {
    chooseFilter(item, object) {
      if (object) {
        this.$emit('filter', {
          identifier: item.identifier,
          item: object,
        });
      }
    },
    filterInputs() {
      this.$emit('input', {
        // processos
        ano: this.inputAno,
        numero: this.inputNumero,
        processoAntigo: this.inputProcessoAntigo,
        data: this.inputData,
        meus_favoritos: this.inputFavorito,
        // documentos
        numero_doc_upload: this.inputNumeroUpload,
        origem_doc_upload: this.inputOrigemUpload,
        data_doc_upload: this.inputData,
        body: this.inputBody,
      });
    },
    filterExport() {
      this.$emit('export', {
        // processos
        ano: this.inputAno,
        numero: this.inputNumero,
        processoAntigo: this.inputProcessoAntigo,
        data: this.inputData,
        meus_favoritos: this.inputFavorito,
      });
    },
    filterPdf() {
      this.$emit('pdf', {
        // processos
        ano: this.inputAno,
        numero: this.inputNumero,
        processoAntigo: this.inputProcessoAntigo,
        data: this.inputData,
        meus_favoritos: this.inputFavorito,
      });
    },
    checkFlags() {
      if (this.organizacao) this.getOrganizacoes();
      if (this.tipodoc) this.getTipoDocs();
      if (this.assunto) this.getAssuntos();
      if (this.nivel) this.getNiveis();
      if (this.situacao) this.getSituacoes();
      if (this.interessado) this.getInteressados();
      if (this.filterusuario) this.getUserSectors();
      if (this.tipoprocesso) this.getTipoProcesso();
    },
    clear(item) {
      this.$emit('clear', item);
    },
    getTipoDocs() {
      this.axios
        .get(`processo/tipodocumento/${this.$store.state.organizacao.id}/org`)
        .then((res) => {
          this.arrayFilters.push({
            arr: res.data,
            identifier: 'tipodoc',
            label: 'Tipo de documento',
          });
        })
        .finally(() => {
          this.count++;
        });
    },
    getOrganizacoes() {
      this.axios
        .get(`${process.env.VUE_APP_PROCESSO_URL}organizacao`)
        .then((res) => {
          this.arrayFilters.push({
            arr: res.data,
            identifier: 'organizacao',
            label: 'Organização',
          });
        })
        .finally(() => {
          this.count++;
        });
    },
    getTipoProcesso() {
      this.axios
        .get(`processo/tipoprocesso/${this.$store.state.organizacao.id}/org`)
        .then((res) => {
          this.arrayFilters.push({
            arr: res.data,
            identifier: 'tipoprocesso',
            label: 'Tipo de processo',
          });
        })
        .finally(() => {
          this.count++;
        });
    },
    getAssuntos() {
      this.axios
        .get(`processo/assunto/${this.$store.state.organizacao.id}/org`)
        .then((res) => {
          this.arrayFilters.push({
            arr: res.data,
            identifier: 'assunto',
            label: 'Assunto',
          });
        })
        .finally(() => {
          this.count++;
        });
    },
    getNiveis() {
      this.axios
        .get(`${process.env.VUE_APP_PROCESSO_URL}nivel`)
        .then((res) => {
          this.arrayFilters.push({
            arr: res.data,
            identifier: 'nivel',
            label: 'Nível',
          });
        })
        .finally(() => {
          this.count++;
        });
    },
    getSituacoes() {
      let filteredSituacoes = this.itemsArr.reduce(function (result, el) {
        if (el.situacao) {
          return result.concat({
            id: el.situacao_id,
            nome: el.situacao,
          });
        }
        return result;
      }, []);
      this.arrayFilters.push({
        arr: filteredSituacoes,
        identifier: 'situacao',
        label: 'Situação',
      });
      this.count++;
    },
    getInteressados() {
      let filteredInteressados = this.itemsArr.reduce(function (result, el) {
        if (el.interessado) {
          return result.concat({
            id: el.id,
            nome: el.interessado,
          });
        }
        return result;
      }, []);
      this.arrayFilters.push({
        arr: filteredInteressados,
        identifier: 'interessado',
        label: 'Interessado',
      });
      this.count++;
    },
    getUserSectors() {
      const setores = this.$store.state.usuario.sectors;
      let usuarios_setor = [];
      for (var i = 0; i < setores.length; i++) {
        this.axios
          .get(`gerencial/users/sector/${setores[i]}`)
          .then((res) => {
            const usuarios = res.data;
            for (var j = 0; j < usuarios.length; j++) {
              if (usuarios[j].active)
                usuarios_setor.push({
                  id: usuarios[j].id,
                  nome: usuarios[j].name,
                });
            }
          })
          .finally(() => {});
      }
      this.arrayFilters.push({
        arr: usuarios_setor,
        identifier: 'filterusuario',
        label: 'Usuário atribuído',
      });
      this.count++;
    },
  },
  mounted() {
    this.checkFlags();
  },
};
</script>
